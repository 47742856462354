@import 'styles/bootstrap-core.scss';

.cardMinHeight {
  min-height: 200px;

  @include media-breakpoint-up(lg) {
    min-height: 500px;
  }
}

.imageCard {
  @include media-breakpoint-down(md) {
    z-index: 100;
  }
}
